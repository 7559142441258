import axios from "axios";
import router from "@/router";
import { getToken } from "./auth";
import { Message } from "element-ui";

const service = axios.create({
  baseURL: global.config.url,
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    if (!config.headers) config.headers = {};
    if (getToken()) {
      config.headers["Authorization"] = `Bearer ${getToken()}`;
    } else {
      config.headers["Authorization"] = "Bearer token";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data.code) {
      if (error.response.data.message === "JWT AUTH ERROR") {
        localStorage.clear();
        router.push({ path: "/login" });
        Message({
          type: "error",
          message: "用户已过期，请重新登录",
        });
      } else {
        Message({
          type: "error",
          message: error.response.data.message,
        });
      }
    }
    return Promise.reject(error);
  }
);

export default service;
